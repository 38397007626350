const {
  checkPermissions,
  checkPermission,
} = require('@/utils/saveDataToLocal');

export const headerMenu = {
  items: [
    {
      show:
        checkPermission('PRODUCT_VIEW') ||
        checkPermission('CATE_VIEW') ||
        checkPermission('PROPERTY_VIEW') ||
        checkPermission('PROVIDER_VIEW') ||
        checkPermission('BRAND_VIEW'),
      title: 'Sản phẩm',
      page: '/products',
      submenu: [
        {
          name: 'Sản phẩm',
          icon: 'menu-icon flaticon-tool',
          page: '/products',
          show: checkPermissions(['PRODUCT_VIEW']),
        },
        {
          name: 'Danh mục sản phẩm',
          icon: 'menu-icon flaticon2-menu-1',
          page: '/categories',
          show: checkPermissions(['CATE_VIEW']),
        },
        {
          name: 'Danh mục nội bộ',
          icon: 'menu-icon flaticon2-menu-1',
          page: '/external-categories',
          show: checkPermissions(['CATE_VIEW']),
        },
        {
          name: 'Tồn kho',
          icon: 'menu-icon flaticon2-supermarket',
          page: '/productStock',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Thuộc tính sản phẩm',
          icon: 'menu-icon flaticon2-tools-and-utensils',
          page: '/properties',
          show: checkPermissions(['PROPERTY_VIEW']),
        },
        {
          name: 'Thương hiệu',
          icon: 'menu-icon flaticon-bell-1',
          page: '/brands',
          show: checkPermissions(['BRAND_VIEW']),
        },
        {
          name: 'Nhà cung cấp',
          icon: 'menu-icon flaticon2-delivery-truck',
          page: '/providers',
          show: checkPermissions(['PROVIDER_VIEW']),
        },
        {
          name: 'Danh sách IMEI',
          icon: 'menu-icon fas fa-barcode',
          page: '/imeis',
          show: checkPermissions(['PRODUCT_VIEW']),
        },
        {
          name: 'Tra cứu IMEI cũ',
          icon: 'menu-icon fas fa-barcode',
          page: '/old-imeis',
          show: checkPermissions(['PRODUCT_VIEW']),
        },
        {
          name: 'Lịch sử IMEI',
          icon: 'menu-icon fas fa-history',
          page: '/imei-histories',
          show: checkPermissions(['IMEI_HISTORY_VIEW']),
        },
        {
          name: 'Thông tin bảo hành',
          icon: 'menu-icon fas fa-tools',
          page: '/warranty-info',
          show: checkPermissions(['PRODUCT_VIEW']),
        },
        {
          name: 'Thời gian lưu kho',
          icon: 'menu-icon fas fa-clock',
          page: '/storage-time',
          show: checkPermissions(['PRODUCT_VIEW']),
        },
        {
          name: 'Tồn ảo sản phẩm',
          icon: 'menu-icon fas fa-tools',
          page: '/virtual-stock',
          show: checkPermissions(['VIRTUAL_STOCK_VIEW']),
        },
      ],
    },
    {
      show: checkPermission('CUSTOMER_VIEW'),
      title: 'Khách hàng',
      page: '/customers',
      submenu: [
        {
          name: 'Khách hàng',
          icon: 'menu-icon flaticon2-group',
          page: '/customers',
          show: checkPermission('CUSTOMER_VIEW'),
        },
        {
          name: 'Khách hàng doanh nghiệp',
          icon: 'menu-icon flaticon2-group',
          page: '/businesses',
          show: true,
        },
        {
          name: 'Chăm sóc khách hàng',
          icon: 'menu-icon flaticon2-group',
          page: '/customer-care',
          show: checkPermission('CUSTOMER_CARE_VIEW'),
        },
        {
          name: 'Hình thức chăm sóc',
          icon: 'menu-icon flaticon2-group',
          page: '/customer-care-form',
          show: checkPermission('CUSTOMER_CARE_VIEW'),
        },
        {
          name: 'Cấu hình phân lọai khách hàng',
          icon: 'menu-icon flaticon2-group',
          route: '/customer-type',
          show: checkPermission('CUSTOMER_CARE_VIEW'),
        },

        {
          name: 'Phiêu chuyển điểm tiêu dùng',
          icon: 'menu-icon flaticon2-group',
          page: '/customer-transfer',
          show: true,
        },
      ],
    },
    {
      show: checkPermission('STOCK_VIEW'),
      title: 'Kho hàng',
      page: '/stocks',
      submenu: [
        {
          name: 'Phiếu yêu cầu',
          icon: 'menu-icon flaticon2-supermarket',
          page: '/purchase-orders',
          show: checkPermission('PO_VIEW'),
        },
        {
          name: 'Phiếu yêu cầu nhập hàng',
          icon: 'menu-icon flaticon2-delivery-package',
          page: '/import-purchase-orders',
          show: checkPermission('PO_IMPORT_STOCK'),
        },
        {
          name: 'Phiếu nhập kho',
          icon: 'menu-icon flaticon2-supermarket',
          page: '/stocks',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Phiếu xuất kho',
          icon: 'menu-icon flaticon2-delivery-package',
          page: '/export-stocks',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Phiếu nhập kho bảo hành',
          icon: 'menu-icon flaticon2-supermarket',
          page: '/import-warranty-stocks',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Phiếu xuất kho bảo hành',
          icon: 'menu-icon flaticon2-delivery-package',
          page: '/export-warranty-stocks',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Phiếu cho mượn máy',
          icon: 'menu-icon flaticon2-supermarket',
          page: '/lending-stock',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Sản phẩm nhập xuất kho',
          icon: 'menu-icon flaticon2-supermarket',
          page: '/product-stockslip',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Phiếu yêu cầu XNK',
          icon: 'menu-icon fas fa-store',
          page: '/require-stocks',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Sản phẩm yêu cầu XNK',
          icon: 'menu-icon fas fa-money-bill-alt',
          page: '/product-require-stocks',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Chuyển kho',
          icon: 'menu-icon flaticon2-paperplane',
          page: '/transfer-stocks',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Phiếu chuyển kho đã xóa',
          icon: 'menu-icon flaticon2-paperplane',
          page: '/transfer-stock-deleted',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Cân kho sản phẩm',
          icon: 'menu-icon fas fa-store',
          page: '/inventory-balance',
          show: true,
        },
        {
          name: 'Cân kho IMEI',
          icon: 'menu-icon fas fa-store',
          page: '/imei-balance',
          show: true,
        },
        {
          name: 'Cronjob',
          icon: 'menu-icon flaticon-calendar-with-a-clock-time-tools',
          page: '/tools/list',
          show: true,
        },
        {
          name: 'Đồng bộ sản phẩm',
          icon: 'menu-icon flaticon2-crisp-icons',
          page: '/tools/sync-product',
          show: true,
        },
        {
          name: 'Đồng bộ hoá đơn',
          icon: 'menu-icon flaticon2-crisp-icons',
          page: '/tools/sync-bill',
          show: true,
        },
        {
          name: 'Đồng bộ imei',
          icon: 'menu-icon flaticon2-crisp-icons',
          page: '/tools/sync-imei',
          show: true,
        },
      ],
    },
    {
      show: checkPermissions(['ORDER_VIEW']),
      title: 'Đơn hàng',
      page: '/orders',
      submenu: [
        {
          name: 'Đơn hàng',
          icon: 'menu-icon flaticon2-supermarket',
          page: '/orders',
          show: checkPermission('STOCK_VIEW'),
        },
        {
          name: 'Đơn đặt trước',
          icon: 'menu-icon flaticon2-supermarket',
          page: '/pre-order',
          show: true,
        },
        {
          name: 'Nguồn đơn hàng',
          icon: 'menu-icon flaticon2-delivery-package',
          page: '/order-source',
          show: checkPermission('ORDER_SOURCE_VIEW'),
        },
        {
          name: 'Lịch sử',
          icon: 'menu-icon fas fa-history',
          page: '/order-history',
          show: true,
        },
      ],
    },
    {
      show: checkPermission('BILL_VIEW'),
      title: 'Bán hàng',
      page: '/bills',
      submenu: [
        {
          name: 'Hóa đơn bán lẻ',
          icon: 'menu-icon far fa-file-alt',
          page: '/bills',
          show: checkPermission('BILL_VIEW'),
        },
        {
          name: 'Hóa đơn bán B2B',
          icon: 'menu-icon fas fa-file-alt',
          page: '/wholesaleInvoices',
          show: checkPermission('BILL_VIEW'),
        },
        {
          name: 'Trả hàng',
          icon: 'menu-icon flaticon-reply',
          page: '/returnBills',
          show: checkPermission('BILL_VIEW'),
        },
        {
          name: 'Hóa đơn đã xóa',
          icon: 'menu-icon far fa-file-alt',
          page: '/bill-deleted',
          show: checkPermission('BILL_VIEW'),
        },
        {
          name: 'Lịch sử',
          icon: 'menu-icon fas fa-history',
          page: '/bill-history',
          show: checkPermission('BILL_VIEW'),
        },
        {
          name: 'Hoá đơn đỏ',
          icon: 'menu-icon fas fa-receipt',
          page: '/vat-invoice',
          show: true,
        },
        {
          name: 'Hoá đơn đỏ chi tiết',
          icon: 'menu-icon fas fa-receipt',
          page: '/vat-invoice-v2',
          show: true,
        },
        {
          name: 'Phiếu yêu cầu bảo hành',
          icon: 'menu-icon flaticon-security',
          page: '/warranty-request',
          show: checkPermission('BILL_VIEW'),
        },
        {
          name: 'Hóa đơn bảo hành',
          icon: 'menu-icon flaticon-security',
          page: '/warranty-bill',
          show: checkPermission('BILL_VIEW'),
        },
      ],
    },
    {
      show: checkPermission('BILL_VIEW'),
      title: 'Mobifone',
      page: '/mbf-transaction',
      submenu: [
        {
          name: 'Hoá đơn mobifone',
          icon: 'menu-icon far fa-file-alt',
          page: '/mbf-transaction',
          show: checkPermission('BILL_VIEW'),
        },
        {
          name: 'Sản phẩm mobifone',
          icon: 'menu-icon flaticon-tool',
          page: '/mbf-product',
          show: checkPermission('BILL_VIEW'),
        },
      ],
    },
    {
      show: checkPermissions(['TRADE_IN_VIEW']),
      title: 'Thu cũ',
      page: '/trade-in',
      submenu: [
        {
          name: 'Phiếu định giá',
          icon: 'menu-icon far fa-file-alt',
          page: '/trade-in',
          show: checkPermission('TRADE_IN_VIEW'),
        },
        {
          name: 'Phiếu thu cũ',
          icon: 'menu-icon far fa-file-alt',
          page: '/import-trade-in',
          show: checkPermission('TRADE_IN_VIEW'),
        },
        {
          name: 'Chương trình thu cũ- đổi mới',
          icon: 'menu-icon fas fa-tools',
          page: '/tradein-programs',
          show: checkPermission('TRADE_IN_PROGRAM_VIEW'),
        },
        {
          name: 'Danh mục linh kiện',
          icon: 'menu-icon fas fa-tools',
          page: '/product-component-categories',
          show: checkPermission('TRADE_IN_PROGRAM_VIEW'),
        },
        {
          name: 'Linh kiện',
          icon: 'menu-icon fas fa-tools',
          page: '/product-component',
          show: checkPermission('TRADE_IN_PROGRAM_VIEW'),
        },
        {
          name: 'Nhóm sản phẩm linh kiện',
          icon: 'menu-icon fas fa-tools',
          page: '/product-component-group',
          show: checkPermission('TRADE_IN_PROGRAM_VIEW'),
        },
        {
          name: 'Phiếu định giá đã xoá',
          icon: 'menu-icon far fa-file-alt',
          route: '/deleted-trade-in',
          show: checkPermission('TRADE_IN_VIEW'),
        },
      ],
    },
    {
      show: checkPermissions([
        'TRANSACTION_VIEW',
        'INSTALLMENT_VIEW',
        'DEBT_CREDIT_VIEW',
        'DEBT_INSTALLMENT_VIEW',
        'DEBT_ACCOUNTANT_VIEW',
        'ACCOUNTANT_VIEW',
      ]),
      title: 'Kế toán',
      page: '/accounting',
      submenu: [
        {
          name: 'Thu chi tiền mặt',
          icon: 'menu-icon fas fa-money-bill',
          page: '/accounting/transactions/cash',
          show: checkPermissions(['TRANSACTION_VIEW']),
        },
        {
          name: 'Thu chi ngân hàng',
          icon: 'menu-icon fas fa-university',
          page: '/accounting/transactions/bank',
          show: checkPermissions(['TRANSACTION_VIEW']),
        },
        {
          name: 'Tổng hợp thu chi',
          icon: 'menu-icon fas fa-receipt',
          page: '/accounting/transactions/cashbook',
          show: checkPermissions(['TRANSACTION_VIEW']),
        },
        {
          name: 'Công nợ nhập hàng',
          icon: 'menu-icon fas fa-shipping-fast',
          page: '/accounting/debts/purchase-order',
          show: false,
        },
        {
          name: 'Công nợ nhà cung cấp',
          icon: 'menu-icon fas fa-shipping-fast',
          page: '/accounting/debts/providers',
          show: checkPermissions(['DEBT_PROVIDER_VIEW']),
        },
        {
          name: 'Hạn thanh toán nhà cung cấp',
          icon: 'menu-icon far fa-calendar-alt',
          page: '/accounting/debts/supplierduedate',
          show: checkPermissions(['DEBT_PROVIDER_VIEW']),
        },
        {
          name: 'Dịch vụ trả góp',
          icon: 'menu-icon fas fa-file-signature',
          page: '/accounting/debts/installments',
          show: checkPermissions(['DEBT_INSTALLMENT_VIEW']),
        },
        {
          name: 'Trả góp hóa đơn',
          icon: 'menu-icon far fa-calendar-alt',
          page: '/accounting/debts/items',
          show: checkPermissions(['DEBT_INSTALLMENT_VIEW']),
        },
        {
          name: 'Trả góp đơn hàng',
          icon: 'menu-icon far fa-calendar-alt',
          page: '/accounting/debts/installment-orders',
          show: checkPermissions(['DEBT_INSTALLMENT_VIEW']),
        },
        {
          name: 'Quẹt thẻ hóa đơn',
          icon: 'menu-icon far fa-calendar-alt',
          page: '/accounting/debts/credits',
          show: checkPermissions(['DEBT_CREDIT_VIEW']),
        },
        {
          name: 'Quẹt thẻ đơn hàng',
          icon: 'menu-icon far fa-calendar-alt',
          page: '/accounting/debts/credit-orders',
          show: checkPermissions(['DEBT_CREDIT_VIEW']),
        },
        {
          name: 'Phí ship đơn hàng',
          icon: 'menu-icon far fa-calendar-alt',
          page: '/accounting/debts/ship-fee-orders',
          show: checkPermissions(['DEBT_CREDIT_VIEW']),
        },
        {
          name: 'Tất cả bút toán',
          icon: 'menu-icon fas fa-file-signature',
          page: '/accounting/transactions/all',
          show: checkPermissions(['TRANSACTION_VIEW']),
        },
        {
          name: 'Tài khoản kế toán',
          icon: 'menu-icon fas fa-file-invoice-dollar',
          page: '/accounting/accounts',
          show: checkPermissions(['ACCOUNTANT_VIEW']),
        },
        {
          name: 'Dịch vụ trả góp',
          icon: 'menu-icon fas fa-file-signature',
          page: '/accounting/installments',
          show: checkPermissions(['INSTALLMENT_VIEW']),
        },
        {
          name: 'Danh mục thu chi',
          icon: 'menu-icon fas fa-file-signature',
          page: '/transaction-categories',
          show: checkPermissions(['ACCOUNTANT_VIEW']),
        },
        {
          name: 'Giải ngân công nợ',
          icon: 'menu-icon fas fa-file-signature',
          page: '/disbursement',
          show: true,
        },
        {
          name: 'Lịch sử thanh toán',
          icon: 'menu-icon fas fa-file-signature',
          page: '/qr-code-history',
          show: true,
        },
        {
          name: 'Trạng thái đồng bộ với Fast',
          icon: 'menu-icon fas fa-address-book',
          route: '/fast-sync',
          show: true,
        },
      ],
    },
    {
      show: checkPermissions(['REPORT_VIEW']),
      title: 'Báo cáo',
      page: '/reports',
      submenu: [
        {
          name: 'Thu chi theo cửa hàng',
          icon: 'menu-icon fas fa-store',
          page: '/reports/accounting/cash',
          show: true,
        },
        {
          name: 'Tổng tiền theo cửa hàng',
          icon: 'menu-icon fas fa-money-bill-alt',
          page: '/reports/accounting/money',
          show: true,
        },
        {
          name: 'Doanh thu theo sản phẩm',
          icon: 'menu-icon fas fa-store',
          page: '/revenue-report/by-product',
          show: true,
        },
        {
          name: 'Báo cáo tổng hợp hoa hồng NCH',
          icon: 'menu-icon fas fa-store',
          page: '/reward-reports/overview',
          show: checkPermissions(['RR_VIEW_ALL']),
        },
        {
          name: 'Báo cáo chi tiết hoa hồng NCH',
          icon: 'menu-icon fas fa-money-bill-alt',
          page: '/reward-reports/detail',
          show: checkPermissions(['RR_VIEW_ALL']),
        },
        {
          name: 'Báo cáo chi tiết hoa hồng NCH theo nhân viên',
          icon: 'menu-icon fas fa-money-bill-alt',
          page: '/reward-reports/detail-by-employee',
          show: true,
        },
        {
          name: 'DS hóa đơn theo CH',
          icon: 'menu-icon fas fa-money-bill-alt',
          page: '/reward-reports/list-bill-by-store',
          show: checkPermissions(['RR_VIEW_ALL']),
        },
        {
          name: 'DS hóa đơn theo NV',
          icon: 'menu-icon fas fa-money-bill-alt',
          page: '/reward-reports/list-bill-by-employee',
          show: true,
        },
        {
          name: 'Hoa hồng CSKH',
          icon: 'menu-icon fas fa-file-invoice-dollar',
          page: '/reward-cr-reports/overview',
          show: checkPermissions(['RR_VIEW_ALL', 'RR_VIEW_BILL']),
        },
        {
          name: 'Hoa hồng NPK',
          icon: 'menu-icon fas fa-file-invoice-dollar',
          page: '/reward-accessory-reports',
          show: checkPermissions(['RR_VIEW_ALL']),
        },
        {
          name: 'Hoa hồng NHĐT theo Tháng',
          icon: 'menu-icon fas fa-money-bill-alt',
          page: '/reward-phone-reports/month-reports',
          show: true,
        },
        {
          name: 'Hoa hồng NHĐT theo Quý',
          icon: 'menu-icon fas fa-money-bill-alt',
          page: '/reward-phone-reports/quater-reports',
          show: false,
        },
        {
          name: 'Sản phẩm XNK',
          icon: 'menu-icon fas fa-file-invoice-dollar',
          page: '/product-stockslip-reports',
          show: checkPermissions(['RR_VIEW_ALL']),
        },
        {
          name: 'Sản phẩm bán chạy',
          icon: 'menu-icon fas fa-file-invoice-dollar',
          page: '/product-sale-reports',
          show: checkPermissions(['RR_VIEW_ALL']),
        },
        {
          name: 'Tổng hợp SP IMEI đã bán',
          icon: 'menu-icon fas fa-file-invoice-dollar',
          page: '/product-imei-sale-reports',
          show: checkPermissions(['RR_VIEW_ALL']),
        },
        {
          name: 'Báo cáo kích hoạt IMEI',
          icon: 'menu-icon fa fa-check-circle',
          page: '/active-imei-report',
          show: checkPermissions(['IMEI_ACTIVE_REPORT_VIEW']),
        },
        {
          name: 'Phiếu không phục vụ được',
          icon: 'menu-icon fas fa-file-invoice-dollar',
          page: '/order-cant-serve',
          show: true,
        },
        {
          name: 'Thống kê lượt phục vụ',
          icon: 'menu-icon fas fa-file-invoice-dollar',
          page: '/customer-serve',
          show: true,
        },
      ],
    },
    {
      show: checkPermissions(['REPORT_VIEW']),
      title: 'Hoa hồng',
      page: '/reward',
      submenu: [
        {
          name: 'Nhóm sản phẩm',
          icon: 'menu-icon fas fa-store',
          page: '/reward/reward-category',
          show: true,
        },
        {
          name: 'Chương trình thưởng hoa hồng ngành cửa hàng',
          icon: 'menu-icon fas fa-money-bill-alt',
          page: '/reward/reward-program',
          show: true,
        },
        {
          name: 'Cộng trừ khác hoa hồng cửa hàng',
          icon: 'menu-icon fas fa-store',
          page: '/reward/reward-detail',
          show: true,
        },
        {
          name: 'Chương trình thưởng hoa hồng Ngành hàng phụ kiện',
          icon: 'menu-icon fas fa-money-bill-alt',
          page: '/reward/reward-accessory-program',
          show: true,
        },
        {
          name: 'Cộng trừ khác hoa hồng ngành hàng phụ kiện',
          icon: 'menu-icon fas fa-store',
          page: '/reward/reward-accessory-detail',
          show: true,
        },
        {
          name: 'Chi phí ngành hàng phụ kiện',
          icon: 'menu-icon fas fa-money-bill-alt',
          page: '/reward/assessory-industry-expense',
          show: true,
        },
        {
          name:
            'Chương trình thưởng hoa hồng ngành hàng điện thoại (Theo Tháng)',
          icon: 'menu-icon fas fa-money-bill-alt',
          page: '/reward/reward-month-phone-program',
          show: true,
        },
        {
          name: 'Chương trình thưởng NHPK',
          icon: 'menu-icon far fa-file-alt',
          page: '/reward-accessory',
          show: true,
        },
        {
          name: 'Báo cáo hoa hồng NHPK',
          icon: 'menu-icon far fa-file-alt',
          page: '/reward-accessory-report',
          show: true,
        },
        {
          name: 'Chương trình thưởng ngành hàng máy',
          icon: 'menu-icon far fa-file-alt',
          page: '/reward-phone',
          show: true,
        },
        {
          name: 'Báo cáo hoa hồng NH máy',
          icon: 'menu-icon far fa-file-alt',
          page: '/reward-phone-report',
          show: true,
        },
      ],
    },
    {
      show:
        checkPermission('STORE_VIEW') || checkPermission('ORDER_SOURCE_VIEW'),
      title: 'Cấu hình',
      page: '/stores',
      submenu: [
        {
          name: 'Cửa hàng',
          icon: 'menu-icon fas fa-store',
          page: '/stores',
          show: checkPermission('STORE_VIEW'),
        },
        {
          name: 'Doanh nghiệp',
          icon: 'menu-icon fas fa-building',
          page: '/companies',
          show: checkPermission('COMPANY_VIEW'),
        },
        {
          name: 'Khu vực',
          icon: 'menu-icon fas fa-chart-area',
          page: '/area',
          show: checkPermission('AREA_VIEW'),
        },
        {
          name: 'Vùng',
          icon: 'menu-icon fas fa-globe',
          page: '/region',
          show: checkPermission('REGION_VIEW'),
        },
        {
          name: 'Điểm tích lũy',
          icon: 'menu-icon flaticon2-group',
          page: '/points',
          show: checkPermission('POINT_VIEW'),
        },
        {
          name: 'Coupon',
          icon: 'menu-icon fas fa-ticket-alt',
          page: '/coupon',
          show: checkPermission('COUPON_VIEW'),
        },
        {
          name: 'Ngành hàng bộ phụ kiện tặng kèm',
          icon: 'menu-icon fas fa-gift',
          page: '/accessories',
          show: checkPermissions(['PRODUCT_ATTACH_VIEW']),
        },
        {
          name: 'Ngành hàng bộ phụ kiện khuyến mãi',
          icon: 'menu-icon fas fa-tags',
          page: '/promotion-accessories',
          show: checkPermissions(['PRODUCT_PROMOTION_VIEW']),
        },
        {
          name: 'Ngành hàng gói bảo hành',
          icon: 'menu-icon fas fa-award',
          page: '/warranty-packages',
          show: checkPermissions(['WARRANTY_VIEW']),
        },
        {
          name: 'Ngành hàng chiết khấu',
          icon: 'menu-icon fas fa-arrow-down',
          page: '/discount-programs',
          show: checkPermissions(['DISCOUNT_VIEW']),
        },
        {
          name: 'Ngành hàng chương trình trả góp',
          icon: 'menu-icon flaticon2-supermarket',
          page: '/installment-program',
          show: true,
        },
        {
          name: 'Chương trình đặt trước',
          icon: 'menu-icon flaticon2-supermarket',
          page: '/pre-order-form',
          show: true,
        },
        {
          name: 'Trạng thái đồng bộ dữ liệu CMS',
          icon: 'menu-icon fas fa-gift',
          page: '/cms-sync',
          show: true,
        },
        {
          name: 'Cấu hình đồng bộ dữ liệu CMS',
          icon: 'menu-icon fas fa-tags',
          page: '/cms-sync/config',
          show: true,
        },
        {
          name: 'Nhân sự',
          icon: 'menu-icon fas fa-people-arrows',
          page: '/employees',
          show: checkPermission('EMPLOYEE_VIEW'),
        },
        {
          name: 'Tài khoản',
          icon: 'menu-icon flaticon2-user',
          page: '/users',
          show: checkPermission('USER_VIEW'),
        },
        {
          name: 'Phân quyền',
          icon: 'menu-icon fas fa-user-cog',
          page: '/permissions',
          show: checkPermission('ROLE_VIEW'),
        },
        {
          name: 'Cấu hình ca làm việc',
          icon: 'menu-icon fas fa-clipboard',
          page: '/shiftWorkConfig',
          show: checkPermission('SHIFTWORK_CONFIG_VIEW'),
        },
        {
          name: 'Cài đặt ca làm việc',
          icon: 'menu-icon flaticon2-settings',
          page: '/shift-work',
          show: checkPermission('SHIFTWORK_VIEW'),
        },
        {
          name: 'Cài đặt',
          icon: 'menu-icon flaticon2-settings',
          page: '/settings',
          show: checkPermission('USER_VIEW'),
        },
        {
          name: 'Cài đặt power bi',
          icon: 'menu-icon flaticon2-settings',
          page: '/power-bi',
          show: checkPermission('USER_VIEW'),
        },
        {
          name: 'Cài đặt nhà phát triển',
          icon: 'menu-icon flaticon2-settings',
          page: '/development-tools',
          show: true,
        },
        {
          name: 'Thông báo Loyalty',
          icon: 'menu-icon flaticon2-settings',
          page: '/loyalty-notifies',
          show: true,
        },
      ],
    },
    {
      show: true,
      title: 'Nhân sự',
      page: '/human-resources',
      submenu: [
        {
          name: 'Bảng lương',
          icon: 'menu-icon fas fa-store',
          page: '/human-resources',
          show: true,
        },
      ],
    },
  ],
};
